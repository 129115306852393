<template>
  <div class="user-name-badge">
    <b-img rounded :alt="userName" :src="initialsAvatar" :width="size + 2" :height="size" class="user-name-badge--avatar mx-1" :style="{ 'margin-bottom': bottomMargin }"></b-img>
    <slot name="caption">
      <span v-if="showCaption" class="user-name-badge--caption">{{userName}}</span>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
div.user-name-badge {
  display: inline-block;
}
</style>

<script>
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-initials-sprites';

const cachedAvatars = {};

const options = {
  backgroundColorLevel: 400,
  fontSize: 44,
  bold: true,
  dataUri: true,
};

export default {
  name: 'UserNameBadge',
  props: {
    userName: {
      default: 'Anonymous',
      type: String,
    },
    size: {
      default: 18,
      type: Number,
    },
    showCaption: {
      default: true,
      type: Boolean,
    },
    bottomMargin: {
      default: '1px',
      type: String,
    },
  },
  computed: {
    initialsAvatar() {
      const avatarSeed = this.userName;
      let avatarSvg = cachedAvatars[avatarSeed];

      if (!avatarSvg) {
        // eslint-disable-next-line no-multi-assign
        avatarSvg = cachedAvatars[avatarSeed] = createAvatar(style, { ...options, seed: avatarSeed });
      }

      return avatarSvg;
    },
  },
};
</script>
