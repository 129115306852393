<template>
  <div>
    <b-navbar
      fixed="top"
      toggleable="lg"
      type="light"
      variant="light"
    >
      <b-navbar-brand to="/">
        <img :src="logoUrl" width="32px"/> Platformă Vânzări
      </b-navbar-brand>

      <b-navbar-toggle target="nav_collapse" />

      <b-collapse
        is-nav
        id="nav_collapse"
      >
        <b-navbar-nav v-if="isLoggedIn">
          <b-nav-item to="/client-order">
            Comenzi
          </b-nav-item>
          <b-nav-item to="/client">
            Clienți
          </b-nav-item>
          <b-nav-item to="/inventory">
            Stocuri
          </b-nav-item>
          <b-nav-item-dropdown text="Aprovizionare" v-if="canAccessProcurement">
            <b-dropdown-item to="/procurement/critical-inventory">Stocuri critice</b-dropdown-item>
            <b-dropdown-item to="/procurement/latent-inventory">Stocuri latente</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item :to="{ name: 'issuedInvoice' }">
            Facturi
          </b-nav-item>
          <b-nav-item :to="{ name: 'deliveryNote' }">
            Avize
          </b-nav-item>
          <b-nav-item :to="{ name: 'productBundle' }">
            Seturi produse
          </b-nav-item>
          <b-nav-item-dropdown text="Administrare" v-if="canAdminister">
            <b-dropdown-item to="/admin/users">Utilizatori</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-if="canAccessWms">
            <template #button-content>
              <font-awesome-icon :icon="['far', 'warehouse']" class="mr-1"/>&nbsp;<span>WMS</span>
            </template>
            <b-dropdown-item to="/wms/picklists">Liste culegere</b-dropdown-item>
            <b-dropdown-item to="/wms/receive-lists">Recepții</b-dropdown-item>
            <b-dropdown-item to="/wms/stowing-lists">Depozitări</b-dropdown-item>
            <b-dropdown-item to="/wms/stocktaking-lists">Inventarieri</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/wms/inventory">Stoc depozit</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/wms/products">Produse</b-dropdown-item>
            <!--
              <b-dropdown-item to="/wms/storage-cells">Celule</b-dropdown-item>
            -->
            <!--
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/wms/app-users">Utilizatori</b-dropdown-item>
            <b-dropdown-item to="/wms/warehouse-operators">Operatori depozit</b-dropdown-item>
            <b-dropdown-item to="/wms/mobile-terminals">Terminale mobile</b-dropdown-item>
            -->
          </b-nav-item-dropdown>

          <b-nav-item>
            <b-spinner small variant="secondary" v-if="xhrSpinnerVisible" label="Se încarcă date"/>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <transition name="fade">
            <b-nav-item v-if="isLoggedIn && orderUpdatesCount">
              <b-badge id="orderUpdatesCountPill" variant="info">{{orderUpdatesCount}} <span v-if="orderUpdatesCount == 1">actualizare</span><span v-else>actualizări</span></b-badge>
              <b-tooltip target="orderUpdatesCountPill" triggers="hover focus click">Există actualizări de la ultima încărcare a listei de comenzi</b-tooltip>
            </b-nav-item>
          </transition>
          <b-nav-item-dropdown right>
            <!-- Using button-content slot -->
            <template slot="button-content">
              <user-name-badge :user-name="fullName" :show-caption="false" :bottom-margin="'0px'" v-if="isLoggedIn"/>
              <i class="fas fa-user-secret mx-1" v-else/>
              <span id="nav_user_name" v-if="isLoggedIn">{{fullName}}</span><span v-else>(neautentificat)</span>
            </template>
            <b-dropdown-item to="/login" v-if="!isLoggedIn">
              Conectare
            </b-dropdown-item>
            <b-dropdown-item to="/profile" v-if="isLoggedIn">
              Profil
            </b-dropdown-item>
            <b-dropdown-item to="/logout" v-if="isLoggedIn">
              Deconectare
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import UserNameBadge from './UserNameBadge.vue';

export default {
  name: 'Navbar',
  computed: {
    ...mapState(['isLoggedIn', 'username', 'fullName', 'orderUpdatesCount', 'xhrRequestRunning']),
    ...mapGetters(['canAdminister', 'canAccessWms', 'canAccessProcurement']),
  },
  components: {
    UserNameBadge,
  },
  methods: {
    ...mapActions(['performNumberOfOrdersWithUpdatesSinceTimestampQuery']),
  },
  data() {
    return {
      xhrSpinnerVisible: false,
      lastXhrCompleted: null,
      lastXhrCompletedIntervalSubscription: null,
      notificationsIntervalSubscription: null,
      notificationsTimeoutSubscription: null,

      logoUrl: '/logo.svg',
    };
  },
  watch: {
    xhrRequestRunning(val) {
      if (val === false) {
        this.lastXhrCompleted = Date.now();
      }
    },
  },
  created() {
    this.logoUrl = window.location.hostname.startsWith('dasco.xconta.cloud')
      ? '/img/client-logos/dasco.png'
      : '/logo.svg';

    this.lastXhrCompletedIntervalSubscription = setInterval(() => {
      if (!this.lastXhrCompleted) {
        return;
      }

      if (Date.now() > this.lastXhrCompleted + 250) {
        this.xhrSpinnerVisible = false;
      } else {
        this.xhrSpinnerVisible = true;
      }
    }, 50);

    this.notificationsIntervalSubscription = setInterval(async () => {
      this.notificationsTimeoutSubscription = setTimeout(async () => {
        await this.performNumberOfOrdersWithUpdatesSinceTimestampQuery();
      }, Math.random() * 1000);
    }, 15000);
  },
  destroyed() {
    if (this.lastXhrCompletedIntervalSubscription) {
      clearInterval(this.lastXhrCompletedIntervalSubscription);
      this.lastXhrCompletedIntervalSubscription = null;
    }
    if (this.notificationsIntervalSubscription) {
      clearInterval(this.notificationsIntervalSubscription);
      this.notificationsIntervalSubscription = null;
    }
    if (this.notificationsTimeoutSubscription) {
      clearTimeout(this.notificationsTimeoutSubscription);
      this.notificationsTimeoutSubscription = null;
    }
  },
};
</script>

<style scoped lang="scss">
nav {
  margin-bottom: 30px;

  background-color: rgba(248, 249, 250, 0.965) !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }

  #nav_user_name {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
