import { Subject } from 'rxjs';

const DefaultOptions = {
  toaster: 'b-toaster-top-center',
  solid: true,
};

const toastSubject = new Subject();

function toast(message, options, ...params) {
  toastSubject.next([message, {
    ...DefaultOptions,
    ...options,
  }, ...params]);
}

export default {
  toast,

  success(message, options, ...params) {
    return toast(message, {
      title: 'Succes',
      variant: 'success',
      ...options,
    }, ...params);
  },

  error(message, options, ...params) {
    return toast(message, {
      title: 'Eroare',
      variant: 'danger',
      ...options,
    }, ...params);
  },

  info(message, options, ...params) {
    return toast(message, {
      title: 'Info',
      variant: 'info',
      ...options,
    }, ...params);
  },

  getObservable() {
    return toastSubject.asObservable();
  },
};
