import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Logout from './views/Logout.vue';
import Home from './views/Home.vue';
import AccessDenied from './views/AccessDenied.vue';
import NotFound from './views/NotFound.vue';

import store from './store';
import { WebAppRole } from './constants';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
        title: 'Pagina de pornire',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {
        title: 'Deconectare',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Autentificare',
        layout: 'public',
      },
    },
    {
      path: '/access-denied',
      name: 'accessDenied',
      component: AccessDenied,
      meta: {
        title: 'Drepturi insuficiente',
      },
    },
    {
      path: '/client-order/issue-invoice/:clientOrderId',
      name: 'clientOrderIssueInvoice',
      meta: {
        requiresAuth: true,
        title: 'Emite factură pentru comandă',
      },
      component: () => import(/* webpackChunkName: "orders" */ './views/IssueInvoiceForClientOrder.vue'),
    },
    {
      path: '/client-order/delivery-note/:clientOrderId',
      name: 'clientOrderDeliveryNote',
      meta: {
        requiresAuth: true,
        title: 'Emite aviz pentru comandă',
      },
      component: () => import(/* webpackChunkName: "orders" */ './views/IssueInvoiceForClientOrder.vue'),
    },
    {
      path: '/client-order/details/:clientOrderId',
      name: 'clientOrderDetails',
      meta: {
        requiresAuth: true,
        title: 'Vizualizare comandă',
      },
      component: () => import(/* webpackChunkName: "orders" */ './views/ClientOrderDetails.vue'),
    },
    {
      path: '/client-order/details/by-code/:clientOrderCode',
      name: 'clientOrderDetailsByCode',
      meta: {
        requiresAuth: true,
        title: 'Vizualizare comandă',
      },
      component: () => import(/* webpackChunkName: "orders" */ './views/ClientOrderDetailsByCode.vue'),
    },
    {
      path: '/client-order/create',
      name: 'clientOrderCreate',
      meta: {
        requiresAuth: true,
        title: 'Crează comandă',
      },
      component: () => import(/* webpackChunkName: "orders" */ './views/CreateClientOrder.vue'),
    },
    {
      path: '/client-order',
      name: 'clientOrder',
      meta: {
        requiresAuth: true,
        title: 'Comenzi',
      },
      component: () => import(/* webpackChunkName: "orders" */ './views/ClientOrders.vue'),
    },
    {
      path: '/client/details/:clientCode',
      name: 'clientDetails',
      meta: {
        requiresAuth: true,
        title: 'Detalii client',
      },
      component: () => import(/* webpackChunkName: "clients" */ './views/ClientDetails.vue'),
    },
    {
      path: '/product/details/:productCode',
      name: 'productDetails',
      meta: {
        requiresAuth: true,
        title: 'Detalii produs',
      },
      component: () => import(/* webpackChunkName: "products" */ './views/ProductDetails.vue'),
    },
    {
      path: '/product-bundle',
      name: 'productBundle',
      meta: {
        requiresAuth: true,
        title: 'Seturi produse',
      },
      component: () => import(/* webpackChunkName: "bundles" */ './views/ProductBundles.vue'),
    },
    {
      path: '/product-bundle/details/:productBundleId',
      name: 'productBundleDetails',
      meta: {
        requiresAuth: true,
        title: 'Detalii set produse',
      },
      component: () => import(/* webpackChunkName: "bundles" */ './views/ProductBundleDetails.vue'),
    },
    {
      path: '/product-bundle/create',
      name: 'productBundleCreate',
      meta: {
        requiresAuth: true,
        title: 'Adăugare set produse',
      },
      component: () => import(/* webpackChunkName: "bundles" */ './views/CreateProductBundle.vue'),
    },
    {
      path: '/client',
      name: 'client',
      meta: {
        requiresAuth: true,
        title: 'Clienți',
      },
      component: () => import(/* webpackChunkName: "clients" */ './views/Clients.vue'),
    },
    {
      path: '/inventory',
      name: 'inventory',
      meta: {
        requiresAuth: true,
        title: 'Stocuri',
      },
      component: () => import(/* webpackChunkName: "inventory" */ './views/Inventory.vue'),
    },
    {
      path: '/procurement/critical-inventory',
      name: 'criticalInventory',
      meta: {
        requiresAuth: true,
        title: 'Stocuri critice',
      },
      component: () => import(/* webpackChunkName: "supply" */ './views/CriticalInventory.vue'),
    },
    {
      path: '/procurement/latent-inventory',
      name: 'latentInventory',
      meta: {
        requiresAuth: true,
        title: 'Stocuri latente',
      },
      component: () => import(/* webpackChunkName: "supply" */ './views/LatentInventory.vue'),
    },
    {
      path: '/issued-invoice',
      name: 'issuedInvoice',
      meta: {
        requiresAuth: true,
        title: 'Facturi emise',
      },
      component: () => import(/* webpackChunkName: "issued-invoices" */ './views/IssuedInvoices.vue'),
    },
    {
      path: '/issued-invoice/details/:issuedInvoiceId',
      name: 'issuedInvoiceDetails',
      meta: {
        requiresAuth: true,
        title: 'Detalii factură',
      },
      component: () => import(/* webpackChunkName: "issued-invoices" */ './views/IssuedInvoiceDetails.vue'),
    },
    {
      path: '/delivery-notes',
      name: 'deliveryNote',
      meta: {
        requiresAuth: true,
        title: 'Avize emise',
      },
      component: () => import(/* webpackChunkName: "issued-invoices" */ './views/DeliveryNotes.vue'),
    },
    {
      path: '/admin/users',
      name: 'adminUsers',
      meta: {
        requiresAuth: true,
        requiresRole: [WebAppRole.Administrator],
        title: 'Administrare utilizatori',
      },
      component: () => import(/* webpackChunkName: "admin-users" */ './views/AdminUsers.vue'),
    },
    {
      path: '/wms/picklists',
      name: 'wmsPicklists',
      meta: {
        requiresAuth: true,
        requiresRole: [WebAppRole.Administrator, WebAppRole.Storekeeper],
        title: 'Liste culegere - WMS',
      },
      component: () => import(/* webpackChunkName: "wms" */ './views/wms/Picklists.vue'),
    },
    {
      path: '/wms/receive-lists',
      name: 'wmsReceiveLists',
      meta: {
        requiresAuth: true,
        requiresRole: [WebAppRole.Administrator, WebAppRole.Storekeeper],
        title: 'Recepții - WMS',
      },
      component: () => import(/* webpackChunkName: "wms" */ './views/wms/ReceiveLists.vue'),
    },
    {
      path: '/wms/stowing-lists',
      name: 'wmsStowingLists',
      meta: {
        requiresAuth: true,
        requiresRole: [WebAppRole.Administrator, WebAppRole.Storekeeper],
        title: 'Depozitări - WMS',
      },
      component: () => import(/* webpackChunkName: "wms" */ './views/wms/StowingLists.vue'),
    },
    {
      path: '/wms/stocktaking-lists',
      name: 'wmsStocktakingLists',
      meta: {
        requiresAuth: true,
        requiresRole: [WebAppRole.Administrator, WebAppRole.Storekeeper],
        title: 'Inventarieri - WMS',
      },
      component: () => import(/* webpackChunkName: "wms" */ './views/wms/StocktakingLists.vue'),
    },
    {
      path: '/wms/inventory',
      name: 'wmsInventory',
      meta: {
        requiresAuth: true,
        requiresRole: [WebAppRole.Administrator, WebAppRole.Storekeeper],
        title: 'Stoc depozit - WMS',
      },
      component: () => import(/* webpackChunkName: "wms" */ './views/wms/Inventory.vue'),
    },
    {
      path: '/wms/products',
      name: 'wmsProducts',
      meta: {
        requiresAuth: true,
        requiresRole: [WebAppRole.Administrator, WebAppRole.Storekeeper],
        title: 'Produse - WMS',
      },
      component: () => import(/* webpackChunkName: "wms" */ './views/wms/Products.vue'),
    },
    {
      path: '/404',
      name: 'notFound',
      meta: {
        title: 'Not found',
      },
      component: NotFound,
    },
    {
      path: '*',
      name: 'defaultRoute',
      meta: {
        title: 'Default Route',
      },
      redirect: (to) => ({ name: 'notFound', query: { fromUrl: to.fullPath } }),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // We don't save the first route when the app starts, as it's always the root path
  if (from.name) {
    store.commit('setLastRoute', from.fullPath);
  }

  // See: https://alligator.io/vuejs/vue-router-modify-head/
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((record) => record.meta && record.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title} - xConta Web`;
  } else {
    document.title = 'Interfață vânzări - xConta Web';
  }

  const [nearest] = to.matched.slice().reverse().slice(0, 1);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isLoggedIn) {
      // Check if we have a role requirement array and if true check the current user's roles against it
      if (nearest && nearest.meta.requiresRole && Array.isArray(nearest.meta.requiresRole)) {
        const requiredRoles = nearest.meta.requiresRole;
        if (!store.state.userRoles.some((r) => requiredRoles.includes(r))) {
          next('/access-denied');
          return;
        }
      }

      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
