<template>
  <div id="app">
    <component :is="layout">
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import toasts from '@/services/toasts';
import httpClient from '@/services/http-client';

const publicLayout = 'public';
const defaultLayout = 'application';

export default {
  name: 'App',
  data() {
    return {
      toastSubscription: null,
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    layout() {
      if (this.$route.name === 'notFound') {
        return this.isLoggedIn ? `${defaultLayout}-layout` : `${publicLayout}-layout`;
      }
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },

  created() {
    this.toastSubscription = toasts.getObservable().subscribe((params) => {
      this.$bvToast.toast(...params);
    });

    // eslint-disable-next-line no-async-promise-executor
    httpClient.interceptors.response.use(undefined, (err) => new Promise(async (reject) => {
      // eslint-disable-next-line no-underscore-dangle
      if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        try {
          await this.$store.dispatch('performLogout');
        } catch (logoutErr) {
          // we can't do anything about a failed logout, really
          // eslint-disable-next-line no-console
          console.error(logoutErr);
        }
        this.$router.push('/login');
      }

      reject(err);
    }));
  },
  destroyed() {
    if (this.toastSubscription) {
      this.toastSubscription.unsubscribe();
    }
  },
};
</script>

<style lang="scss">
body {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), url("/img/backgrounds/email-pattern.png") repeat scroll 0% 0%;
}

#app {
  margin-top: 80px;
  margin-bottom: 50px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.component-fade-leave-active {
  transition: opacity .15s ease;
}
.component-fade-enter-active {
  transition: opacity .25s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
