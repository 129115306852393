import axios from 'axios';
import Cookies from 'js-cookie';
import { parse, stringify } from 'qs';

const instance = axios.create({
  paramsSerializer: {
    encode: parse,
    serialize: (params) => stringify(params, { arrayFormat: 'repeat' }),
  },
});

instance.interceptors.request.use((config) => {
  const csrfCookie = Cookies.get('XSRF-TOKEN');
  if (csrfCookie) {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-XSRF-TOKEN'] = csrfCookie;
  }

  return config;
});

export default instance;
