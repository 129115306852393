<template>
  <div class="page-wrap d-flex flex-row align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                <span class="display-1 d-block">404</span>
                <div class="mb-4 lead">Pagina pe care o căutai nu a fost găsită.</div>
                <a href="/" class="btn btn-link">Înapoi la pagina de pornire</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  data() {
    return {
    };
  },
};
</script>
