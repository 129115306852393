<template>
  <b-container fluid>
    <h1>Drepturi insuficiente</h1>
    <p>Nu ai drepturi necesare pentru a accesa această pagină!</p>
  </b-container>
</template>

<script>
export default {
  name: 'AccessDenied',
};
</script>
