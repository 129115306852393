<template>
  <b-container fluid>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  created() {
    this.logout();
  },
  computed: {
    ...mapState(['isLoggedIn']),
  },
  methods: {
    ...mapActions([
      'performLogout',
    ]),
    async logout() {
      const logoutResult = await this.performLogout();
      if (logoutResult) {
        this.$router.push('/login');
      }
    },
  },
};
</script>
