/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

import httpClient from '@/services/http-client';
import { WebAppRole, ClientOrderStatus, EventLogSubjectType } from './constants';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userGuid: null,
    username: null,
    fullName: 'Anonymous',
    isLoggedIn: false,
    userRoles: [],
    xhrRequestRunning: false,
    lastOrdersListQueryTimestamp: new Date(),
    orderUpdatesCount: 0,
    prefersInvoiceValueWithVat: localStorage.getItem('preferences.prefersInvoiceValueWithVat') === 'true',
    prefersClientOrdersListFiltersSaving: localStorage.getItem('preferences.prefersClientOrdersListFiltersSaving') === 'true',
    clientOrdersListFilters: localStorage.getItem('preferences.clientOrdersListFilters'),
    lastRoute: null,
  },

  getters: {
    canAdminister(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator) !== -1;
    },
    canIssueClientInvoice(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer) !== -1;
    },
    canIssueClientDeliveryNote(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer) !== -1;
    },
    canPrepareClientOrder(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.Storekeeper) !== -1;
    },
    canCompleteClientOrder(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer || r === WebAppRole.Storekeeper) !== -1;
    },
    canReopenClientOrder(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer || r === WebAppRole.Storekeeper) !== -1;
    },
    canCreateClientOrder(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.SalesAgent) !== -1;
    },
    canIssueInvoice(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer) !== -1;
    },
    canExportIssueInvoice(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer) !== -1;
    },
    canExportDeliveryNote(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer) !== -1;
    },
    canViewInventoryPrice(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.InvoiceIssuer) !== -1;
    },
    canAccessWms(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.Storekeeper) !== -1;
    },
    canAccessProcurement(state) {
      return state.userRoles.findIndex((r) => r === WebAppRole.Administrator || r === WebAppRole.Procurement) !== -1;
    },
  },

  mutations: {
    setLastRoute(state, lastRoute) {
      state.lastRoute = lastRoute;
    },

    setUserGuid(state, userGuid) {
      state.userGuid = userGuid;
    },

    setUsername(state, username) {
      state.username = username;
    },

    setFullName(state, fullName) {
      state.fullName = fullName;
    },

    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },

    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },

    mark_xhr_request_running(state) {
      state.xhrRequestRunning = true;
    },

    unmark_xhr_request_running(state) {
      state.xhrRequestRunning = false;
    },

    resetLastOrdersListQueryTimestamp(state) {
      state.lastOrdersListQueryTimestamp = new Date();
      state.orderUpdatesCount = 0;
    },

    setOrderUpdatesCount(state, count) {
      state.orderUpdatesCount = count;
    },

    setInvoiceValueWithVatPreference(state, preference) {
      localStorage.setItem('preferences.prefersInvoiceValueWithVat', preference ? 'true' : 'false');
      state.prefersInvoiceValueWithVat = !!preference;
    },

    setClientOrdersListFiltersSavingPreference(state, preference) {
      localStorage.setItem('preferences.prefersClientOrdersListFiltersSaving', preference ? 'true' : 'false');
      state.prefersClientOrdersListFiltersSaving = !!preference;
    },

    setClientOrdersListFilters(state, filters) {
      localStorage.setItem('preferences.clientOrdersListFilters', filters);
      state.clientOrdersListFilters = filters;
    },
  },

  actions: {
    setLastRoute({ dispatch }, lastRoute) {
      dispatch('setLastRoute', lastRoute);
    },

    async performDocProcessXmlOrderImport({ dispatch }, importData) {
      dispatch('mark_xhr_request_running');

      const formData = new FormData();
      formData.append('ClientCode', importData.clientCode);
      formData.append('OrderImportXmlFile', importData.file);

      const response = await httpClient.post('/api/client-order/import-docprocess-xml', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performExcelOrderImport({ dispatch }, importData) {
      try {
        dispatch('mark_xhr_request_running');

        const formData = new FormData();
        formData.append('ClientCode', importData.clientCode);
        formData.append('SplitStandardVatProductsInSeparateOrders', importData.splitStandardVatProductsInSeparateOrders);
        formData.append('OrderImportExcelFile', importData.file);

        const response = await httpClient.post('/api/client-order/import-excel', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performInvoiceValueWithVatPreferenceChange({ commit }, preference) {
      commit('setInvoiceValueWithVatPreference', !!preference);
    },

    async performClientOrdersListFiltersSavingStorePreferenceChange({ commit }, preference) {
      commit('setClientOrdersListFiltersSavingPreference', !!preference);
    },

    async performClientOrdersListFiltersChange({ commit }, filters) {
      commit('setClientOrdersListFilters', filters);
    },

    async performDispatchDelegateLookup({ dispatch }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client-order/issue-invoice/delegates');

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performRollBackClientOrderStatus({ dispatch }, clientOrderId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/roll-back/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performNumberOfOrdersWithUpdatesSinceTimestampQuery({ state, commit }) {
      // We don't mark a XHRHttpRequest for this
      if (!state.isLoggedIn) {
        return Promise.resolve(false);
      }

      const statusCodes = [];
      if (state.userRoles.includes(WebAppRole.Administrator)) {
        statusCodes.push(ClientOrderStatus.DRAFT);
      }
      if (state.userRoles.includes(WebAppRole.Storekeeper) || state.userRoles.includes(WebAppRole.Administrator)) {
        statusCodes.push(ClientOrderStatus.SUBMITTED);
      }
      if (state.userRoles.includes(WebAppRole.InvoiceIssuer) || state.userRoles.includes(WebAppRole.Administrator)) {
        statusCodes.push(ClientOrderStatus.PREPARED);
      }

      const response = await httpClient.get('/api/client-order/updates-count', {
        params: {
          timestamp: state.lastOrdersListQueryTimestamp,
          statusCodesFilter: statusCodes.length ? statusCodes.join(',') : undefined,
        },
      });

      commit('setOrderUpdatesCount', response.data);

      return response.data;
    },

    async performClientOrdersQuery({ commit, dispatch }, {
      page,
      perPage,
      orderBy,
      orderByDirection,
      queryTerm,
      filters,
    }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client-order/list', {
        params: {
          page,
          perPage,
          orderBy,
          orderByDirection,
          queryTerm,
          filters,
        },
      });

      commit('resetLastOrdersListQueryTimestamp');

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientDetailsLookup({ dispatch }, {
      clientCode,
    }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client/lookup', {
        params: {
          lookupTerm: clientCode,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performProductBundlesQuery({ dispatch },
      {
        page,
        perPage,
        orderBy,
        orderByDirection,
        queryTerm,
      }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/product-bundle/list', {
        params: {
          page,
          perPage,
          orderBy,
          orderByDirection,
          queryTerm,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performIssuedInvoicesQuery({ dispatch }, {
      page,
      perPage,
      orderBy,
      orderByDirection,
      queryTerm,
      filters,
    }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/issued-invoice/list', {
        params: {
          page,
          perPage,
          orderBy,
          orderByDirection,
          queryTerm,
          filters,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performDeliveryNotesQuery({ dispatch }, {
      page,
      perPage,
      orderBy,
      orderByDirection,
      queryTerm,
      filters,
    }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/delivery-note/list', {
        params: {
          page,
          perPage,
          orderBy,
          orderByDirection,
          queryTerm,
          filters,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientDeliveryAddressesQuery({ dispatch }, {
      clientCode,
      page,
      perPage,
      orderBy,
      orderByDirection,
    }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client/delivery-addresses-list', {
        params: {
          clientCode,
          page,
          perPage,
          orderBy,
          orderByDirection,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderCreation({ dispatch }, { clientCode, clientDeliveryAddressId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post('/api/client-order/create', {
        clientCode,
        clientDeliveryAddressId,
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performAgentVanzariQuery({ dispatch }, salesAgentGuids) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client-order/sales-agents', {
        params: {
          salesAgentGuids,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientWithDeliveryAddressLookup({ dispatch }, params) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client-order/lookup-client', {
        params: {
          ...params,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderDetailsQuery({ dispatch }, { clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/details/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderDetailsByCodeQuery({ dispatch }, { clientOrderCode }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/id-for-order-code/${clientOrderCode}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderLinesByCodeQuery({ dispatch }, { clientOrderCode }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/lines/by-code/${clientOrderCode}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderLinesByIdQuery({ dispatch }, { clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/lines/by-id/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderInvoicesQuery({ dispatch }, { clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/invoices/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderDeliveryNotesQuery({ dispatch }, { clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/delivery-notes/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderInventorySelectionEntriesQuery({ dispatch }, { clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/inventory-selection/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderAvailableInventoryEntriesQuery({ dispatch }, { catalogItemCode }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/available-inventory/${catalogItemCode}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderInventorySelectionEntryRemoval({ dispatch }, { clientOrderId, inventorySelectionEntryId }) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.delete(`/api/client-order/inventory-selection/${clientOrderId}/${inventorySelectionEntryId}`);

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performClientOrderAvailableInventorySelection({ dispatch }, { clientOrderId, performAutomaticInventorySelection, entries }) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post(`/api/client-order/inventory-selection/${clientOrderId}`, {
          performAutomaticInventorySelection,
          entries,
        });

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performSendIssuedInvoiceEmail({ dispatch }, { issuedInvoice, recipient, message }) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post('/api/issued-invoice/send-email', {
          documentType: issuedInvoice.documentType,
          issuedInvoiceNumber: issuedInvoice.number,
          issuedInvoiceYear: issuedInvoice.issuingYear,
          recipient,
          message,
        });

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performClientEmailQueryByCode({ dispatch }, { partnerCode }) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.get(`/api/issued-invoice/partner/email-address/${partnerCode}`);

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performEventLogsQueryByIdentifier({ dispatch }, { subjectIdentifier, subjectType }) {
      dispatch('mark_xhr_request_running');

      try {
        let response;

        if (subjectType === EventLogSubjectType.Order) {
          response = await httpClient.get(`/api/client-order/event-logs/${subjectIdentifier}`);
        } else if (subjectType === EventLogSubjectType.Invoice) {
          response = await httpClient.get(`/api/issued-invoice/event-logs/${subjectIdentifier}`);
        } else {
          throw new Error(`Unknown subject type: ${subjectType}`);
        }

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performClientOrderDiscountRulesQuery({ dispatch }, { clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/discount-rules/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderLineDeletion({ dispatch }, clientOrderLineId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.delete(`/api/client-order/line/${clientOrderLineId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderMultipleLinesSubmission({ dispatch }, orderLines) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post('/api/client-order/lines', orderLines);

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performClientOrderLinesImport({ dispatch }, { clientOrderId, orderLines }) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post(`/api/client-order/lines-import/${clientOrderId}`, orderLines);

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performClientOrderLineSubmission({ dispatch }, orderLine) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post('/api/client-order/line', orderLine);

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performProductBundleLineSubmission({ dispatch }, productBundleLine) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post('/api/product-bundle/line', productBundleLine);

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performClientOrderSubmission({ dispatch }, clientOrderId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/submit/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderSalesRemarksSubmission({ dispatch }, { clientOrderId, remarks }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/sales-remarks/${clientOrderId}`, { remarks });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderWarehouseRemarksSubmission({ dispatch }, { clientOrderId, remarks }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/warehouse-remarks/${clientOrderId}`, { remarks });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderCancellation({ dispatch }, clientOrderId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/cancel/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performCompleteClientOrder({ dispatch }, clientOrderId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/complete/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performReopenClientOrder({ dispatch }, clientOrderId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/reopen/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderBeginPreparing({ dispatch }, clientOrderId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/begin-preparing/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderFinishPreparing({ dispatch }, clientOrderId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/finish-preparing/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performIssueInvoiceForClientOrder({ dispatch }, { clientOrderId, dispatchDetails }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/issue-invoice/${clientOrderId}`, dispatchDetails);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performIssueDeliveryNoteForClientOrder({ dispatch }, { clientOrderId, dispatchDetails }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/delivery-note/${clientOrderId}`, dispatchDetails);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performCancelInvoice({ dispatch }, issuedInvoiceId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/issued-invoice/cancel/${issuedInvoiceId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performIssuedInvoiceDetailsQuery({ dispatch }, { issuedInvoiceId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/issued-invoice/details/${issuedInvoiceId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performIssuedInvoiceSentEmailsQuery({ dispatch }, { issuedInvoiceId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/issued-invoice/emails/${issuedInvoiceId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performRevertInvoiceCancellation({ dispatch }, issuedInvoiceId) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/client-order/issued-invoice/un-cancel/${issuedInvoiceId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientOrderCatalogItemSelectionLookup({ dispatch }, lookupParams) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client-order/lookup-inventory', {
        params: {
          ...lookupParams,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performProcurementSupply({ dispatch }, lookupParams) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post('/api/procurement/inventory-report', lookupParams);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performProductBundleItemsSelectionLookup({ dispatch }, lookupParams) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/product-bundle/lookup-products/${lookupParams.productBundleId}`, {
        params: {
          queryString: lookupParams.lookupTerm,
        },
      });

      dispatch('unmark_xhr_request_running');
      return response.data;
    },

    async performUsersListLookup({ dispatch }, {
      lookupTerm,
      roleFilter = null,
      onlyActive = false,
    }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client-order/users-list', {
        params: {
          queryFilter: lookupTerm,
          roleFilter,
          onlyActive,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientLookup({ dispatch }, { lookupTerm }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/client/lookup', {
        params: {
          lookupTerm,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performClientDetailsQuery({ dispatch }, clientCode) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.get(`/api/client/details/${clientCode}`);
        if (response.isAxiosError) {
          throw response;
        }
        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performProductDetailsLookup({ dispatch }, productCode) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.get(`/api/product/details/${productCode}`);
        if (response.isAxiosError) {
          throw response;
        }
        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performPlatformUsersList({ dispatch }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get('/api/admin/users/list');

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performAddPlatformUser({ dispatch }, payload) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post('/api/admin/users/new', payload);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performEditPlatformUser({ dispatch }, payload) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.put('/api/admin/users/edit', payload);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performChangePlatformUserPassword({ dispatch }, payload) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.put('/api/admin/users/change-password', payload);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performProductBundleDetailsQuery({ dispatch }, { productBundleId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/product-bundle/details/${productBundleId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performProductBundleSetStatus({ dispatch }, { productBundleId, shouldArchive }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post(`/api/product-bundle/set-status/${productBundleId}`, {
        shouldArchive,
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performProductBundleCreate({ dispatch }, {
      codSetProduse,
      denumire,
      descriere,
      esteActiv,
    }) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post('/api/product-bundle/create', {
          codSetProduse,
          denumire,
          descriere,
          esteActiv,
        });

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performProductBundleLineDelete({ dispatch }, { linieSetProduseID }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.delete('/api/product-bundle/delete-line', {
        params: {
          linieSetProduseID,
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performProductBundleUpdate({ dispatch }, {
      setProduseID,
      codSetProduse,
      denumire,
      descriere,
      esteActiv,
    }) {
      dispatch('mark_xhr_request_running');

      try {
        const response = await httpClient.post(`/api/product-bundle/edit/${setProduseID}`, {
          codSetProduse,
          denumire,
          descriere,
          esteActiv,
        });

        if (response.isAxiosError) {
          throw response;
        }

        return response.data;
      } finally {
        dispatch('unmark_xhr_request_running');
      }
    },

    async performProductBundleLinesLookup({ dispatch }, { productBundleId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/product-bundle/lines/${productBundleId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performFileAttachmentsListLookup({ dispatch }, { clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.get(`/api/client-order/file-attachment/list/${clientOrderId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performFileAttachmentsAdd({ dispatch }, { fileAttachment, clientOrderId }) {
      dispatch('mark_xhr_request_running');

      const formData = new FormData();

      fileAttachment.file.forEach((file) => {
        formData.append('AttachmentFiles', file);
      });

      if (fileAttachment.comment) {
        formData.append('Comment', fileAttachment.comment);
      }

      const response = await httpClient.post(`/api/client-order/file-attachment/${clientOrderId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performFileAttachmentDelete({ dispatch }, { clientOrderId, fileId }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.delete(`/api/client-order/file-attachment/${clientOrderId}/${fileId}`);

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performLogout({ dispatch, commit }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post('/api/auth/logout').catch((err) => err.response);

      if (response.data) {
        commit('setIsLoggedIn', false);
        commit('setUserGuid', null);
        commit('setUsername', null);
        commit('setFullName', 'Anonymous');
        commit('setUserRoles', []);
      }

      dispatch('unmark_xhr_request_running');

      return response.data;
    },

    async performLogin({ dispatch, commit }, { username, password, recaptcha }) {
      dispatch('mark_xhr_request_running');

      const response = await httpClient.post('/api/auth/login', {
        username,
        password,
        recaptcha,
      }).catch((err) => err.response);

      const isLoggedIn = response.data && Array.isArray(response.data.roles);

      commit('setIsLoggedIn', isLoggedIn);
      commit('setUserGuid', isLoggedIn && response.data.userGuid);
      commit('setUsername', isLoggedIn && username);
      commit('setFullName', isLoggedIn && response.data.fullName);
      commit('setUserRoles', isLoggedIn ? response.data.roles : []);

      dispatch('unmark_xhr_request_running');

      return isLoggedIn;
    },

    mark_xhr_request_running({ commit }) {
      commit('mark_xhr_request_running');
    },

    unmark_xhr_request_running({ commit }) {
      commit('unmark_xhr_request_running');
    },
  },
});
