export const WebAppRole = {
  Administrator: 'Administrator',
  InvoiceIssuer: 'InvoiceIssuer',
  SalesAgent: 'SalesAgent',
  Storekeeper: 'Storekeeper',
  Automation: 'Automation',
  Procurement: 'Procurement',
};

export const ClientOrderStatus = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  PREPARING: 'PREPARING',
  PREPARED: 'PREPARED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const EventLogSubjectType = {
  Order: 'Order',
  Invoice: 'Invoice',
};

export const EventLogEventType = {
  CreateIssuedInvoice: 'CreateIssuedInvoice',
  IssuedInvoiceCancellation: 'IssuedInvoiceCancellation',
  IssuedInvoiceDownload: 'IssuedInvoiceDownload',
  RevertIssuedInvoiceCancellation: 'RevertIssuedInvoiceCancellation',
  ClientOrderStatusChange: 'ClientOrderStatusChange',
  ClientOrderFileAttachmentAdd: 'ClientOrderFileAttachmentAdd',
  ClientOrderFileAttachmentDelete: 'ClientOrderFileAttachmentDelete',
  ClientOrderFileAttachmentDownload: 'ClientOrderFileAttachmentDownload',
};
