<template>
  <b-container fluid>
    <h1>Interfață vânzări</h1>
    <div class="d-flex justify-content-center">
    <b-card-group deck>
      <b-card align="left">
        <b-card-text>
          <h4>Comenzi</h4>
          <span>Lista cu comenzile active sau încheiate.</span>
        </b-card-text>
        <template #footer>
          <router-link :to="{ name: 'clientOrder' }" class="float-right">Accesează</router-link>
        </template>
      </b-card>

      <b-card align="left">
        <b-card-text>
          <h4>Clienți</h4>
          <span>Accesează catalogul de clienți și inițiază comenzi noi.</span>
        </b-card-text>
        <template #footer>
          <router-link :to="{ name: 'client' }" class="float-right">Accesează</router-link>
        </template>
      </b-card>

      <b-card align="left">
        <b-card-text>
          <h4>Stocuri</h4>
          <span>Interoghează situația curentă a stocurilor.</span>
        </b-card-text>
        <template #footer>
          <router-link :to="{ name: 'inventory' }" class="float-right">Accesează</router-link>
        </template>
      </b-card>

      <b-card align="left">
        <b-card-text>
          <h4>Aprovizionare - stocuri critice</h4>
          <span>Interoghează situația curentă a stocurilor critice.</span>
        </b-card-text>
        <template #footer>
          <router-link :to="{ name: 'criticalInventory' }" class="float-right">Accesează</router-link>
        </template>
      </b-card>

      <b-card align="left">
        <b-card-text>
          <h4>Aprovizionare - stocuri latente</h4>
          <span>Interoghează situația curentă a stocurilor latente.</span>
        </b-card-text>
        <template #footer>
          <router-link :to="{ name: 'latentInventory' }" class="float-right">Accesează</router-link>
        </template>
      </b-card>

      <b-card align="left">
        <b-card-text>
          <h4>Facturi emise</h4>
          <span>Accesează lista de facturi emise.</span>
        </b-card-text>
        <template #footer>
          <router-link :to="{ name: 'issuedInvoice' }" class="float-right">Accesează</router-link>
        </template>
      </b-card>

      <b-card align="left">
        <b-card-text>
          <h4>Avize emise</h4>
          <span>Accesează lista de avize de expediție emise.</span>
        </b-card-text>
        <template #footer>
          <router-link :to="{ name: 'deliveryNote' }" class="float-right">Accesează</router-link>
        </template>
      </b-card>
    </b-card-group>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'home',
};
</script>
