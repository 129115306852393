import Vue from 'vue';
import { BButton } from 'bootstrap-vue';

import store from './store';

/**
 * A "well-known" bogus render function that we can check for and discard
 * @param {Function} createElement function to perform the rendering
 */
function nopRender(createElement) {
  return createElement(
    'div',
    {
      style: {
        background: 'yellow',
      },
    },
    'This is child element',
  );
}

Vue.config.optionMergeStrategies.render = (parentVal, childVal) => {
  // If we have a real parent render function and our bogus "well-known" child render function,
  // just throw away the child render function and tweak the parent one to take into consideration
  // the store XHR request running state
  if (parentVal && childVal === nopRender) {
    return function render(...stuff) {
      if (stuff.length > 1 && stuff[1].props) {
        // eslint-disable-next-line no-param-reassign
        stuff[1] = {
          ...stuff[1],
          props: {
            ...stuff[1].props,
            disabled: stuff[1].props.disabled || store.state.xhrRequestRunning,
          },
        };
      }
      return parentVal.apply(this, stuff);
    };
  }

  return childVal || parentVal;
};

const SuperButton = Vue.extend({
  name: 'SuperButton',
  mixins: [BButton],
  // We set a "well-known" bogus function here that we can look out for when merging the "render" property
  render: nopRender,
});

Vue.component('super-button', SuperButton);

export default SuperButton;
