import './custom.scss';
import '@fortawesome/fontawesome-free/css/all.css';

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueShortkey from 'vue-shortkey';
import Multiselect from 'vue-multiselect';
import Clipboard from 'v-clipboard';

import { Settings } from 'luxon';

import router from './router';
import store from './store';
import httpClient from './services/http-client';

import ApplicationLayout from './layouts/ApplicationLayout.vue';
import PublicLayout from './layouts/PublicLayout.vue';
import App from './App.vue';

Settings.defaultLocale = 'ro';
Settings.defaultZone = 'Europe/Bucharest';

require('./plugins/fontawesome');
require('./super-button');

Vue.component('multiselect', Multiselect);
Vue.component('application-layout', ApplicationLayout);
Vue.component('public-layout', PublicLayout);

Vue.use(Clipboard);

Vue.use(BootstrapVue);

Vue.use(VueShortkey);

Vue.config.productionTip = false;

(async () => {
  const result = await httpClient.get('/api/auth/session');

  store.commit('setIsLoggedIn', result.data.isAuthenticated);
  store.commit('setUserGuid', result.data.userGuid);
  store.commit('setUsername', result.data.username);
  store.commit('setFullName', result.data.fullName);
  store.commit('setUserRoles', result.data.roles);

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
})();
