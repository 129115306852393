import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Reference: https://fontawesome.com/v5/search

import { faFileCertificate as farFileCertificate, faWarehouse as farWarehouse, faBoxOpen as farBoxOpen } from '@fortawesome/pro-regular-svg-icons';
import { faFileCertificate as fasFileCertificate, faScanner as fasScanner, faInventory as fasInventory } from '@fortawesome/pro-solid-svg-icons';

// Register all the icons here
// See: https://github.com/FortAwesome/vue-fontawesome#usage
library.add(farFileCertificate);
library.add(fasFileCertificate);
library.add(fasScanner);
library.add(fasInventory);

library.add(farWarehouse);
library.add(farBoxOpen);

Vue.component('font-awesome-icon', FontAwesomeIcon);
